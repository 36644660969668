import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Race: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 43.01 43.79" className={clss}>
            <g>
                <path
                    d="M36.79 0a6.23 6.23 0 0 0-6.22 6.22c0 2.82 4 9.54 5.18 11.4l-18.06 5L30.36 29 8.23 41.57l.08-.13C9 40.35 15 30.67 15 26.88a7.52 7.52 0 0 0-15 0c0 2.85 3.41 9 5.4 12.37.34-.64.69-1.3 1-2-2-3.55-4.55-8.39-4.55-10.41a5.66 5.66 0 0 1 11.32 0c0 2.34-3.34 8.41-5.45 11.94-.4.78-.81 1.55-1.22 2.28l.22.34.46.74.92 1.61L34.3 28.9l-11.46-5.79 14-3.85.74-1.26c.55-.88 5.43-8.7 5.43-11.8A6.23 6.23 0 0 0 36.79 0Zm0 15.74c-1.94-3.23-4.36-7.83-4.36-9.52a4.36 4.36 0 0 1 8.72 0c0 1.69-2.42 6.29-4.36 9.52Z"
                    fill={color}
                />
                <path
                    d="M36.79 3.48a2.91 2.91 0 1 0 2.91 2.91 2.91 2.91 0 0 0-2.91-2.91Zm0 4a1.05 1.05 0 1 1 1-1.05 1 1 0 0 1-1 1.01ZM10.92 27.08a3.4 3.4 0 1 0-3.4 3.4 3.4 3.4 0 0 0 3.4-3.4Zm-3.4 1.53a1.53 1.53 0 1 1 1.53-1.53 1.53 1.53 0 0 1-1.53 1.53Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default Race;
